import MenuItems from './MenuItems.js';
import { APP_DOMAIN_PREFIX } from '../../../environment/index.js';
import '../scss/dropdown.scss';

const menuItems = [
    {
        title: 'HOME',
        url: '/',
    },
    {
        title: 'ABOUT',
        url: '/about-us',
        submenu: [
            {
                title: 'OVERVIEW',
                url: '/about-us',
            },
            {
                title: 'WAPDA AUTHORITY',
                submenu: [
                    {
                        title: 'WAPDA',
                        url: '/present',
                        param:0,
                    },
                    {
                        title: 'MoWR',
                        url: '/present',
                        param:1,

                    },

                ]
            },
            {
                title: 'WAPDA AS AN ORGANIZATION',
                submenu: [
                    {
                        title: 'Water Wing',
                        url: '/wapda-page',
                        param:0,

                    },
                    {
                        title: 'Power Wing',
                        url: '/wapda-page',
                        param:1,
                    },
                    {
                        title: 'Finance Wing',
                        url: '/wapda-page',
                        param:2,
                    },
                    {
                        title: 'Administration',
                        url: '/wapda-page',
                        param:3
                    }
                ]
            },
            {
              title:'WAPDA ARTICLES',
              submenu:[
                {
                    title:'Kalabagh Dam',
                    url:'/Kalabagh-dam'
                },
                {
                    title:'Research Paper',
                    url:'/research-paper'
                },
                {
                    title:'E-Library',
                    url:'/library'
                }
              ]
            },
            {
              title:'WAPDA REGULATIONS',
              url:'/wapda-regulations'
            },
            {
                title: 'ORGANOGRAM',
                url: '/organo-page',
            },
            {
              title: 'WAPDA ACT',
              url: '/wapda-act',
            }
        ]
    }, {
        title: 'PROJECTS',
        url: '/projects',
        submenu: [
            {
                title: 'HYDROPOWER & WATER',
                submenu: [
                    {
                        title: 'O&M',
                        url: '/projects/63c2ac4e5f1f55cb03a15d4f',
                        param: 0,
                    },
                    {
                        title: 'UNDER CONSTRUCTION',
                        url: '/projects/63c2ac4e5f1f55cb03a15d4f',
                        param: 1,
                    },
                    {
                        title: 'READY FOR CONSTRUCTION',
                        url: '/projects/63c2ac4e5f1f55cb03a15d4f',
                        param: 2,
                    },
                    {
                        title: 'FUTURE CONSTRUCTION',
                        url: '/projects/63c2ac4e5f1f55cb03a15d4f',
                        param: 3,
                    },

                ]
            },
            // {
            //     title: 'WATER SECTOR',
            //     submenu: [
            //         {
            //             title: 'O&M',
            //             url: '/projects/63c2ac775f1f55cb03a15d59',
            //             param: 0,
            //         },
            //         {
            //             title: 'UNDER CONSTRUCTION',
            //             url: '/projects/63c2ac775f1f55cb03a15d59',
            //             param: 1,
            //         },
            //         {
            //             title: 'READY FOR CONSTRUCTION',
            //             url: '/projects/63c2ac775f1f55cb03a15d59',
            //             param: 2,
            //         },
            //         {
            //             title: 'FUTURE CONSTRUCTION',
            //             url: '/projects/63c2ac775f1f55cb03a15d59',
            //             param: 3,
            //         },

            //     ]
            // },
        ]
    }, {
        title: 'INVESTORS',
        url: '/investors',
        submenu: [
            {
                title: 'OVERVIEW',
                url: '/investors'
            },
            {
                title: 'HYDEL TARIFF',
                url: '/investors',
                param: 1
            },
            {
                title: 'LATEST ANNUAL REPORTS',
                url: '/investors',
                param: 2
            },
            {
                title: 'FINANCIAL INFORMATION',
                url: '/investors',
                param: 3
            },
            {
                title: 'GENERATION LICENSE',
                url: '/investors',
                param: 4
            },
            {
                title: 'GREEN EURO BONDS',
                url: '/investors',
                param: 5
            }
        ]
    }, {
        title: 'SPORTS',
        url: '/wapda-sports',
        param: 2
    }, {
        title: 'CSR',
        url: '',
        submenu: [
            {
                title: 'HEALTHCARE',
                url: '/health-care'
            },
            {
                title: 'EDUCATION',
                url: '/education'
            },
            {
                title: 'TRAINING',
                url: '/training'
            },
        ]
    }, {
        title: 'CAREERS',
        url: '/careers',
    }, {
        title: 'MEDIA',
        url: '',
        submenu: [
            {
                title: 'News & Views',
                url: '/news'
            },
            {
                title: 'News Letters',
                url: '/news',
                param: 3
            },
            {
                title: 'Galleria',
                url: '/media-gallery'
            },
            {
                title: 'Video Spotlights',
                url: '/video-gallery'
            }
        ]
    }, {
        title: 'TENDERS',
        url: '/tenders',
    }
];

const Navbar = () => {
    return (
        <nav>
            <ul className="menus">
                {
                    menuItems.map((menu, index) => {
                        const depthlevel = 0;
                        return <MenuItems items={menu} key={index} depthlevel={depthlevel} />
                    })
                }
            </ul>
        </nav>
    )
}

export default Navbar;