import React from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom"; 
import FrontLayoutRoutes from "./frontend/frontRoutes"; 
import {Confirmation} from "../components";
import ReactGA from 'react-ga4';
import { TRACKING_ID } from '../environment/index.js';
  
const Home =  React.lazy(() => import('../container/frontend/home'));
const Media =  React.lazy(() => import('../container/frontend/mediaGallery'));
const NewsDetails =  React.lazy(() => import('../container/frontend/newsDetails'));
const VideoGallery =  React.lazy(() => import('../container/frontend/videoGallery'));
const News =  React.lazy(() => import('../container/frontend/news'));
const Tenders =  React.lazy(() => import('../container/frontend/tenders'));
const Projects =  React.lazy(() => import('../container/frontend/projects'));
const ProjectDetails =  React.lazy(() => import('../container/frontend/projectDetails'));
const Investors =  React.lazy(() => import('../container/frontend/investors'));
const HydelTariff =  React.lazy(() => import('../container/frontend/wapdaAchievements'));
const GenerationLicense =  React.lazy(() => import('../container/frontend/wapdaAchievements/generationLicense'));
const AboutUs =  React.lazy(() => import('../container/frontend/aboutUs'));
const WapdaPage =  React.lazy(() => import('../container/frontend/wapdaOrganizationPage'));
const OrganoGaram =  React.lazy(() => import('../container/frontend/organogaram'));
const PresentSetup =  React.lazy(() => import('../container/frontend/presentSetup'));
const Careers =  React.lazy(() => import('../container/frontend/careers'));
const Disclaimer =  React.lazy(() => import('../container/frontend/disclaimer'));
const Copyright =  React.lazy(() => import('../container/frontend/disclaimer/copyright'));
const Accessibilities =  React.lazy(() => import('../container/frontend/disclaimer/accesiblity'));
const Policy =  React.lazy(() => import('../container/frontend/disclaimer/policy'));
const WapdaRegulations =  React.lazy(() => import('../container/frontend/wapdaRegulation'));
const ELibrary =  React.lazy(() => import('../container/frontend/wapdaAtricles/elibrary'));
const ResearchPaper =  React.lazy(() => import('../container/frontend/wapdaAtricles/researchPaper'));
const KalaBaghDam =  React.lazy(() => import('../container/frontend/wapdaAtricles/kalabaghDam'));
const WhatWeDo =  React.lazy(() => import('../container/frontend/whatWeDo'));
const ContactUs =  React.lazy(() => import('../container/frontend/contactUs'));
const HealthCare =  React.lazy(() => import('../container/frontend/csr/health'));
const Education =  React.lazy(() => import('../container/frontend/csr/education'));
const Training =  React.lazy(() => import('../container/frontend/csr/training'));
const WapdaDiscipline =  React.lazy(() => import('../container/frontend/wapdasports/sportsWapda'));
const SportsMore =  React.lazy(() => import('../container/frontend/wapdasports/sportsMore/sportsMore'));
const ExamNotification =  React.lazy(() => import('../container/frontend/examNotification'));
const RiverFlow =  React.lazy(() => import('../container/frontend/riverFlow'));
const HydroReservior =  React.lazy(() => import('../container/frontend/hydroReservior'));
const Newsletter =  React.lazy(() => import('../container/frontend/news/newletterPDF'));
const EmployeeCorner =  React.lazy(() => import('../container/frontend/employeeCorner'));
const WapdaAct =  React.lazy(() => import('../container/frontend/wapdaAct'));

ReactGA.initialize(TRACKING_ID);
function Routes() {
  var url = document.URL.split("/"); 
  if(url.length>3 && url[3]=="logout"){
    localStorage.clear();
    let domLink=url[0]+"//"+url[2]; 
    window.location.replace(domLink);
  }
  return (
    <Router>
      <Switch>
        <FrontLayoutRoutes exact path="/"  routeName={'home'} render={matchprops => (<Home {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/tenders"  routeName={'tenders'} render={matchprops => (<Tenders {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/media-gallery"  routeName={'media'} render={matchprops => (<Media {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/news-details/:id"  routeName={'newsDetail'} render={matchprops => (<NewsDetails {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/news"  routeName={'news'} render={matchprops => (<News {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/projects/:id"  routeName={'projects'} render={matchprops => (<Projects {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/project-details/:id"  routeName={'projectDetails'} render={matchprops => (<ProjectDetails {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/video-gallery"  routeName={'video'} render={matchprops => (<VideoGallery {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/investors"  routeName={'investors'} render={matchprops => (<Investors {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/hydel-tariff"  routeName={'hydelTariff'} render={matchprops => (<HydelTariff {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/generation-license"  routeName={'generationLicense'} render={matchprops => (<GenerationLicense {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/river-flow"  routeName={'riverFLow'} render={matchprops => (<RiverFlow {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/about-us"  routeName={'about'} render={matchprops => (<AboutUs {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/wapda-page"  routeName={'wapdaPage'} render={matchprops => (<WapdaPage {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/organo-page"  routeName={'organoPage'} render={matchprops => (<OrganoGaram {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/present"  routeName={'present'} render={matchprops => (<PresentSetup {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/careers"  routeName={'present'} render={matchprops => (<Careers {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/disclaimer"  routeName={'disclaimer'} render={matchprops => (<Disclaimer {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/copyright"  routeName={'copyright'} render={matchprops => (<Copyright {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/accesiblity"  routeName={'accesiblity'} render={matchprops => (<Accessibilities {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/Policy"  routeName={'Policy'} render={matchprops => (<Policy {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/wapda-regulations"  routeName={'wapdaRegulations'} render={matchprops => (<WapdaRegulations {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/Library"  routeName={'ELibrary'} render={matchprops => (<ELibrary {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/research-paper"  routeName={'researchPaper'} render={matchprops => (<ResearchPaper {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/Kalabagh-dam"  routeName={'KalaBaghDam'} render={matchprops => (<KalaBaghDam {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/what-we-do"  routeName={'WhatWeDo'} render={matchprops => (<WhatWeDo {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/Contact-us"  routeName={'ContactUs'} render={matchprops => (<ContactUs {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/health-care"  routeName={'HealthCare'} render={matchprops => (<HealthCare {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/education"  routeName={'Education'} render={matchprops => (<Education {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/training"  routeName={'Training'} render={matchprops => (<Training {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/wapda-sports"  routeName={'wapdaSports'} render={matchprops => (<WapdaDiscipline {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/sports-more/:id?"  routeName={'sportsMore'} render={matchprops => (<SportsMore {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/exam-notification"  routeName={'examNotification'} render={matchprops => (<ExamNotification {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/hydro-reservior"  routeName={'hydroReservior'} render={matchprops => (<HydroReservior {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/newsletter"  routeName={'newsletter'} render={matchprops => (<Newsletter {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/employee-corner"  routeName={'employeeCorner'} render={matchprops => (<EmployeeCorner {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/wapda-act"  routeName={'WapdaAct'} render={matchprops => (<WapdaAct {...matchprops} />)} />

      </Switch>
      <Confirmation/>
    </Router>
  )
    ;
}

export default Routes;