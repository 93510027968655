import TableCell from "@material-ui/core/TableCell"; 
import Alert from "./alert";
import NoData from './noData' 
import Loader from './loading';    
import InputError from './inputComponents/inputError'; 
import Confirmation from './confirmation'  
import ExpandMore from './expandMore'; 
import CustomTabs from './customTabs/customTabs'
const TableNormalCell = TableCell

export {
  Alert, NoData,  CustomTabs, Loader,  InputError, 
  Confirmation,   TableNormalCell,ExpandMore
}
