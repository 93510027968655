import {
  dashboardIcon,
  userIcon,
} from '../assets/images/img';

let isLocal = false;
let cmsUrl = "";
let backendUrl = "";
let frontendUrl = ""; 

if (isLocal) {
  cmsUrl = `http://localhost:3001`;
  backendUrl = `http://localhost:3004`;
  frontendUrl = `http://localhost:3000`;
}
else {
  cmsUrl = `https://cms.wapda.gov.pk`;
  backendUrl = `https://wapda-api.rockvillegroup.com`; //wapda-api.rockvillegroup.com,wapda-api-stg.rockvillegroup.com
  frontendUrl = `https://wapda.gov.pk`;
}
export const CMS_DOMAIN_PREFIX = cmsUrl;
export const APP_DOMAIN_PREFIX = backendUrl;
export const APP_FRONT_PREFIX = frontendUrl;


export const TRACKING_ID = "G-V9ZR8QQHSR";

export const ASSET_BASE_URL = "https://d3vnf7txgu507y.cloudfront.net/assets/";
 
export const API_URL = `${APP_DOMAIN_PREFIX}/api/v1`;
export const FILE_BASE_URL = 'http=//localhost=9000/';

export const LABELS = {
  GO_BACK: '← Back',
  LOGOUT: '↶ Logout',
  LOGIN: 'Login',
  SIGNUP: 'Sign Up',
  REGISTER: 'Create User',
  EMAIL: 'Email Address',
  NAME: 'Username',
  FULL_NAME: 'Full Name',
  PASSWORD: 'Password',
  CONFIRM_PASSWORD: 'Confirm Password',
  INVALID_MOBILE: 'Invalid mobile number'
};
export const PASSWORD = {
  passwordLength: 6,
  passwordLengthError: 'password is to short'
}
export const REGISTER = {
  SUCCESS_HEADER: "Success",
  SUCCESS_MESSAGE: "User Created Successfully!",
  FAILURE_HEADER: "Failure",
  FAILURE_MESSAGE: "Cannot Create User! User may already have been created with the given email!"
};
export const REGEXP_EMAIL = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2;3})+$/;
export const C_OTC_STORAGE = 'c_d_storage';


export const eventPoints =
  [
    'President Gold Cup International Squash Tournament, 3-6 Jan 2017 at Islamabad',
    'Series of 3 Hockey Test matches with Oman Hockey Team, 21-25 Jan 2017 at Oman Hockey Stadium',
    'Davis Cup Tie against Iran, 3-5 Feb 2017 at Islamabad',
    'International Beach Handball Championship, 22-26 Feb 2017 at Dubai, UAE',
    '13th West Asia Baseball Cup, 25 Feb to 1st March 2017 at Islamabad',
    '4th South Asian Wushu Championship, 4-11 March 2017 in Pakistan',
    '5-Test Match Series & 4-Nation Darwin Hockey Tournament in Australia 12 March to 4 April 2017',
    'Belt Wrestling Grand-Prix, 14-18 March 2017, Bishkek (Kyrgyzstan)',
    '4th Behria Town International Women Squash Championship, 30 March to 2 April 2017 at Rawalpindi',
    'International Field Hockey Tournament, 31st March to 2nd April 2017 at USA',
    '23rd Seamaster (TTF-Master) Asian Table Tennis Championship, 9-16 April 2017 at Wuxi, China',
    '7th Asian Taekwondo Clubs Championship, 13-14 April 2017 at Sari (Iran)',
    '8th Dushanbe International Half – Marathon, 15th April 2017 at Dushanbe City, Tajikistan.',
    '28th Fajr Open G-1 Taekwondo Championship, 16-17 April 2017 at Sari (Iran)',
    '10th DAHA Fajr International Kabaddi Championship (Men & Women), 16-24 April 2017 at Mashhad, Iran',
    'Asian Senior Weightlifting Championships, 22-29 April 2017 atAshgabat, Turkmenistan',
    'Asian Grand Prix Series, 1st & 2nd Leg on 24 & 27April at China & 3rd Leg on 30th April 2017 at Chinese Taipei',
    '19th Asian Individual Squash Championship, 26-30 April, 2017 at Chennai (India)',
    'Asian U-23 Volleyball Championship, 28 April to 10 May 2017 at Iran',
    'Asian Boxing Championship, 28th April to 7th May 2017 at Tashkent (Uzbekistan)',
    'Women Hockey Series with Indonesian Women Hockey Team, 30th April to 12th May 2017 at Indonesia',
    '2ndAsian Men’s U-23 Volleyball Championship, 1-9 May, 2017 in Ardabil, Iran',
    '10th Asian Youth Girls Netball Championship, 6-13 May 2017 at Jeonju, Korea',
    'Asian Senior Wrestling Championship, 10-14 May 2017, New Delhi, India',
    '4th Islamic Solidarity Games, 12-22 May 2017, Baku Azerbaijan',
    'Asian Senior Judo Championship, 23-28 May 2017 at Hong Kong',
    'World Individual Table Tennis Championship, 29.05.2017 to 06.06.2017 at Dusseldorf, Germany',
    '4th Islamic Games, 8-24 May 2017 at Azherbaijan',
    'World Qualifying Round in Kyrgyzstan, 24-31 May 2017',
    'Hockey World League Semi Final, 15-25 June 2017 at London',
    'World Taekwondo Championship, 23-30 June 2017 at Muju, Korea',
    'Asian Men’s Club Volleyball Championship, 26.06.2017 to 05.07.2017 at Hanoi, Vietnam',
    '22nd Asian Athletics Championships, 6-9 July 2017 at Bhubaneshwar, India',
    '3rd CMS Broneo Junior Open Squash Championship, 7-11 July 2017 at Kuching Sarawak, Malaysia',
    'World Youth Beach Handball Championship 11-16 July 2017, Mauritius',
    '14th Asian Karate Championship, 9-17 July 2017 at Astana Kazakhstan',
    'Mega Squash Event, 12-16 July 2017 at Islamabad',
    '17th FINA World Championship, 14-30 July 2017 at Budapest, Hungary',
    'VI Commonwealth Youth Games, 19-23 July 2017 at Nassau, Bahamas',
    'Volleyball Practice Matches, 15-21 July 2017 at Qatar',
    'Asian Youth & Junior Weightlifting Championships, 20-29 July 2017 at Kathmandu, Nepal',
    '19th Asian Senior Men’s Volleyball Championship, 24.07.2017 to 01.08.2017 at Surabaya, Indonesia',
    'Japan Junior Open Squash Championships, 25-28 July 2017 at Yokohama, Japan',
    '51st IAAF Congress & IAAF World Championships, 1-13 August 2017 at London',
    '4th South Asian Karate Championship, 3-6 August 2017 at Colombo, Sri Lanka',
    'Asian Ju-Jitsu Championship, 11-14 August 2017 at Hanoi, Vietnam',
    '3rd WTF World Taekwondo Cadet Championship, 24-27 Aug 2017 at Egypt',
    'Asian Women Baseball Championship, 2-7 September 2017 at Hong Kong',
    'Commonwealth Senior, Junior & Youth Weightlifting Championship, 3-9 Sep 2017 at Gold Coast, Australia',
    'US Mid Amateur Golf Championship, 5-8 Sep 2017 in USA',
    'Asian Powerlifting Bench Press Championship, 6-12 September 2017 at Philippines',
    '9th Asian Age Group Swimming Championship, 9-16 September 2017, Tashkent, Uzbekistan',
    'Davis Cup Asian Oceana Group-II Final, 15-17 Sep 2017 at Islamabad',
    'World Road Cycling Championship, 16-22 September 2017 at Bergan, Norway',
    '5th Asian Indoor & Martial Arts Games, 17-27 September 2017 at Ashgabat, Turkmenistan',
    'International Taekwondo Tournament “Palestine Open”, 28-30 September 2017 at Ramalah, Palestine',
    'Canada Open Taekwondo Tournament, 28th Sep to 1st Oct 2017 at Montreal, Canada',
    '28th Asian Baseball Championship, 2-8 Oct 2017 at Taiwan',
    '10th Men’s Asia Cup (Hockey), 12-22 October 2017 at Dhaka, Bangladesh',
    'World Championships Pahalwani Style, 13-15 October 2017 at Dalyan, Turkey',
    'Senior Amateurs Golf Championship, 17-20 Oct 2017 at Australia',
    'World Championships Wrestling Grappling, 18-21 Oct 2017 at Baku, Azerbaijan',
    '20th Asian Archery Championship, 24.11.2017 to 01.12.2017 at Dhaka, Bangladesh',
    'Asian Classic Powerlifting Championship, 4-9 Dec 2017 at Karala, India',
    'Oman Fiber Optic International Open Table Tennis Tournament. 7-11 Dec 2017 at Oman',
    'Asian Men’s Netball Championship, 13-17 Dec 2017 at Kuala Lumpur, Malaysia',
    'Islamic Countries Weightlifting Championships, 18-23 Dec 2017 at Cairo, Egypt',
  ]


export const achievementPoints =
  [
    { events: '1st International Open Amateur Golf Championship', SNo: '1' },
    { events: '13th West Asia Baseball cup', SNo: '2' },
    { events: '28th Fajr Open Taekwondo Championship', SNo: '3' },
    { events: '4th Asian Karate Championship', SNo: '4' },
    { events: '4th Islamic Solidarity Games', SNo: '5' },
    { events: '5th Asian Indoor and Martial art Games', SNo: '6' },
    { events: '6th Pars wushu cup', SNo: '7' },
    { events: 'Commonwealth Weightlifting Championship', SNo: '8' },
    { events: 'Davis Cup Tie against Iran', SNo: '9' },
    { events: 'Davis Cup Tie against Thailand', SNo: '10' },
    { events: 'Friendly Women Hockey series', SNo: '11' },
    { events: 'International Beach Handball', SNo: '12' },
    { events: 'International Challenge Boxing fight', SNo: '13' },
    { events: 'International Challenge Boxing fight 1', SNo: '14' },
    { events: 'International Challenge Boxing fight 2', SNo: '15' },
    { events: 'International Corporate Badminton', SNo: '16' },
    { events: 'International Squash series', SNo: '17' },
    { events: 'President Gold Cup Squash tournament', SNo: '18' },
    { events: 'Series of Three Hockey Test matches', SNo: '19' },
    { events: 'World Karate 1-series', SNo: '20' },
    { events: 'Summary', SNo: '21' },

  ]


export const disciplineTableData = [
  { num: '1', discipline: 'Archery (Men & Women)', SNo: '20', Machine: 'Machine Rowing' },
  { num: '2', discipline: 'Athletics (Men & Women)', SNo: '21', Machine: 'Netball(Men & Women)' },
  { num: '3', discipline: 'Badminton (Men & Women)', SNo: '23', Machine: 'Powerlifting (Men & Women)' },
  { num: '4', discipline: 'Baseball', SNo: '24', Machine: 'Rifleshooting (Men & Women)' },
  { num: '5', discipline: 'Basketball (Men & Women)', SNo: '25', Machine: 'Rowing' },
  { num: '6', discipline: 'Bodybuilding', SNo: '26', Machine: 'Rugby (Men & Women)' },
  { num: '7', discipline: 'Boxing', SNo: '27', Machine: 'Sailing' },
  { num: '8', discipline: 'Chess', SNo: '28', Machine: 'Softball (Men & Women)' },
  { num: '9', discipline: 'Cricket', SNo: '29', Machine: 'Squash (Men & Women)' },
  { num: '10', discipline: 'Cycling (Men & Women)', SNo: '30', Machine: 'Swimming (Men & Women)' },
  { num: '11', discipline: 'Football (Men & Women)', SNo: '31', Machine: 'Table Teniis (Men & Women)' },
  { num: '12', discipline: 'Golf (Men & Women)', SNo: '32', Machine: 'Taekwondo (Men & Women)' },
  { num: '13', discipline: 'Gymnastic', SNo: '33', Machine: 'Tennis (Men & Women)' },
  { num: '14', discipline: 'Handball (Men & Women)', SNo: '34', Machine: 'Tug of War' },
  { num: '15', discipline: 'Hockey (Men & Women)', SNo: '35', Machine: 'Vollyball (Men & Women)' },
  { num: '16', discipline: 'Judo (Men & Women)', SNo: '36', Machine: 'Wushu (Men & Women)' },
  { num: '17', discipline: 'Ju-Jitsu (Men & Women)', SNo: '37', Machine: 'Weightlifting (Men & Women)' },
  { num: '18', discipline: 'Karate', SNo: '38', Machine: 'Wrestling (Men & Women)' },
  { num: '19', discipline: 'Kabbadi', SNo: '', Machine: '' },
]

export const disciplineCategory =
  [
    { title: "Introduction to Sports", id:"6416e3acfb49b1b7957862c1"},
    { title: "Objectives", id:"6416e428fb49b1b7957862d2" },
    { title: "Organogram", id:"64195967c030b5130b7ef9ae" },
    { title: "Sports Setup", id:"6416e6c5fb49b1b7957862e3" },
    { title: "Facilities", 
      subCategories:[
        {title: 'Infratstucture' , id:"6416e88ffb49b1b7957862f4"},
        {title: 'S.O.P Renting Out' , id:"6416eb36fb49b1b795786349"},
      ]
    },
    { title: "Constitution", id:"6416ec89fb49b1b79578635a" },
    // { title: "Disciplines" },
    // { title: "Endowment Fund for Sports" },
    { title: "Seminars", id:"6416f740fb49b1b79578636b" },

  ]


export const sidebarTabsList = [
  { text: 'dashboard', key: 'dashboard', icon: dashboardIcon },
  { text: 'user management', key: 'users', icon: userIcon },
];

export const jobStatus = [
  'pending', 'assigned', 'in route', 'delivered', 'completed',
];

export const userRoles = [
  { text: 'Admin', value: '1' }
]
export const userStatus = [
  { text: 'Active', value: '1' },
  { text: 'In-Active', value: '0' },
]
export const statusTypeOptions = [
  { text: 'All', value: '' },
  { text: 'Active', value: '1' },
  { text: 'In-Active', value: '0' },
]

export const country = [
  {
    text: 'Afghanistan',
    countryCodes: ['93'],
    value: 'AF',
    isoCode3: 'AFG',
  },
  {
    text: 'Albania',
    countryCodes: ['355'],
    value: 'AL',
    isoCode3: 'ALB',
  },
  {
    text: 'Algeria',
    countryCodes: ['213'],
    value: 'DZ',
    isoCode3: 'DZA',
  },
  {
    text: 'American Samoa',
    countryCodes: ['1-684'],
    value: 'AS',
    isoCode3: 'ASM',
  },
  {
    text: 'Andorra',
    countryCodes: ['376'],
    value: 'AD',
    isoCode3: 'AND',
  },
  {
    text: 'Angola',
    countryCodes: ['244'],
    value: 'AO',
    isoCode3: 'AGO',
  },
  {
    text: 'Anguilla',
    countryCodes: ['1-264'],
    value: 'AI',
    isoCode3: 'AIA',
  },
  {
    text: 'Antarctica',
    countryCodes: ['672'],
    value: 'AQ',
    isoCode3: 'ATA',
  },
  {
    text: 'Antigua and Barbuda',
    countryCodes: ['1-268'],
    value: 'AG',
    isoCode3: 'ATG',
  },
  {
    text: 'Argentina',
    countryCodes: ['54'],
    value: 'AR',
    isoCode3: 'ARG',
  },
  {
    text: 'Armenia',
    countryCodes: ['374'],
    value: 'AM',
    isoCode3: 'ARM',
  },
  {
    text: 'Aruba',
    countryCodes: ['297'],
    value: 'AW',
    isoCode3: 'ABW',
  },
  {
    text: 'Australia',
    countryCodes: ['61'],
    value: 'AU',
    isoCode3: 'AUS',
  },
  {
    text: 'Austria',
    countryCodes: ['43'],
    value: 'AT',
    isoCode3: 'AUT',
  },
  {
    text: 'Azerbaijan',
    countryCodes: ['994'],
    value: 'AZ',
    isoCode3: 'AZE',
  },
  {
    text: 'Bahamas',
    countryCodes: ['1-242'],
    value: 'BS',
    isoCode3: 'BHS',
  },
  {
    text: 'Bahrain',
    countryCodes: ['973'],
    value: 'BH',
    isoCode3: 'BHR',
  },
  {
    text: 'Bangladesh',
    countryCodes: ['880'],
    value: 'BD',
    isoCode3: 'BGD',
  },
  {
    text: 'Barbados',
    countryCodes: ['1-246'],
    value: 'BB',
    isoCode3: 'BRB',
  },
  {
    text: 'Belarus',
    countryCodes: ['375'],
    value: 'BY',
    isoCode3: 'BLR',
  },
  {
    text: 'Belgium',
    countryCodes: ['32'],
    value: 'BE',
    isoCode3: 'BEL',
  },
  {
    text: 'Belize',
    countryCodes: ['501'],
    value: 'BZ',
    isoCode3: 'BLZ',
  },
  {
    text: 'Benin',
    countryCodes: ['229'],
    value: 'BJ',
    isoCode3: 'BEN',
  },
  {
    text: 'Bermuda',
    countryCodes: ['1-441'],
    value: 'BM',
    isoCode3: 'BMU',
  },
  {
    text: 'Bhutan',
    countryCodes: ['975'],
    value: 'BT',
    isoCode3: 'BTN',
  },
  {
    text: 'Bolivia',
    countryCodes: ['591'],
    value: 'BO',
    isoCode3: 'BOL',
  },
  {
    text: 'Bosnia and Herzegovina',
    countryCodes: ['387'],
    value: 'BA',
    isoCode3: 'BIH',
  },
  {
    text: 'Botswana',
    countryCodes: ['267'],
    value: 'BW',
    isoCode3: 'BWA',
  },
  {
    text: 'Brazil',
    countryCodes: ['55'],
    value: 'BR',
    isoCode3: 'BRA',
  },
  {
    text: 'British Indian Ocean Territory',
    countryCodes: ['246'],
    value: 'IO',
    isoCode3: 'IOT',
  },
  {
    text: 'British Virgin Islands',
    countryCodes: ['1-284'],
    value: 'VG',
    isoCode3: 'VGB',
  },
  {
    text: 'Brunei',
    countryCodes: ['673'],
    value: 'BN',
    isoCode3: 'BRN',
  },
  {
    text: 'Bulgaria',
    countryCodes: ['359'],
    value: 'BG',
    isoCode3: 'BGR',
  },
  {
    text: 'Burkina Faso',
    countryCodes: ['226'],
    value: 'BF',
    isoCode3: 'BFA',
  },
  {
    text: 'Burundi',
    countryCodes: ['257'],
    value: 'BI',
    isoCode3: 'BDI',
  },
  {
    text: 'Cambodia',
    countryCodes: ['855'],
    value: 'KH',
    isoCode3: 'KHM',
  },
  {
    text: 'Cameroon',
    countryCodes: ['237'],
    value: 'CM',
    isoCode3: 'CMR',
  },
  {
    text: 'Canada',
    countryCodes: ['1'],
    value: 'CA',
    isoCode3: 'CAN',
  },
  {
    text: 'Cape Verde',
    countryCodes: ['238'],
    value: 'CV',
    isoCode3: 'CPV',
  },
  {
    text: 'Cayman Islands',
    countryCodes: ['1-345'],
    value: 'KY',
    isoCode3: 'CYM',
  },
  {
    text: 'Central African Republic',
    countryCodes: ['236'],
    value: 'CF',
    isoCode3: 'CAF',
  },
  {
    text: 'Chad',
    countryCodes: ['235'],
    value: 'TD',
    isoCode3: 'TCD',
  },
  {
    text: 'Chile',
    countryCodes: ['56'],
    value: 'CL',
    isoCode3: 'CHL',
  },
  {
    text: 'China',
    countryCodes: ['86'],
    value: 'CN',
    isoCode3: 'CHN',
  },
  {
    text: 'Christmas Island',
    countryCodes: ['61'],
    value: 'CX',
    isoCode3: 'CXR',
  },
  {
    text: 'Cocos Islands',
    countryCodes: ['61'],
    value: 'CC',
    isoCode3: 'CCK',
  },
  {
    text: 'Colombia',
    countryCodes: ['57'],
    value: 'CO',
    isoCode3: 'COL',
  },
  {
    text: 'Comoros',
    countryCodes: ['269'],
    value: 'KM',
    isoCode3: 'COM',
  },
  {
    text: 'Cook Islands',
    countryCodes: ['682'],
    value: 'CK',
    isoCode3: 'COK',
  },
  {
    text: 'Costa Rica',
    countryCodes: ['506'],
    value: 'CR',
    isoCode3: 'CRI',
  },
  {
    text: 'Croatia',
    countryCodes: ['385'],
    value: 'HR',
    isoCode3: 'HRV',
  },
  {
    text: 'Cuba',
    countryCodes: ['53'],
    value: 'CU',
    isoCode3: 'CUB',
  },
  {
    text: 'Curacao',
    countryCodes: ['599'],
    value: 'CW',
    isoCode3: 'CUW',
  },
  {
    text: 'Cyprus',
    countryCodes: ['357'],
    value: 'CY',
    isoCode3: 'CYP',
  },
  {
    text: 'Czech Republic',
    countryCodes: ['420'],
    value: 'CZ',
    isoCode3: 'CZE',
  },
  {
    text: 'Democratic Republic of the Congo',
    countryCodes: ['243'],
    value: 'CD',
    isoCode3: 'COD',
  },
  {
    text: 'Denmark',
    countryCodes: ['45'],
    value: 'DK',
    isoCode3: 'DNK',
  },
  {
    text: 'Djibouti',
    countryCodes: ['253'],
    value: 'DJ',
    isoCode3: 'DJI',
  },
  {
    text: 'Dominica',
    countryCodes: ['1-767'],
    value: 'DM',
    isoCode3: 'DMA',
  },
  {
    text: 'Dominican Republic',
    countryCodes: ['1-809', '1-829', '1-849'],
    value: 'DO',
    isoCode3: 'DOM',
  },
  {
    text: 'East Timor',
    countryCodes: ['670'],
    value: 'TL',
    isoCode3: 'TLS',
  },
  {
    text: 'Ecuador',
    countryCodes: ['593'],
    value: 'EC',
    isoCode3: 'ECU',
  },
  {
    text: 'Egypt',
    countryCodes: ['20'],
    value: 'EG',
    isoCode3: 'EGY',
  },
  {
    text: 'El Salvador',
    countryCodes: ['503'],
    value: 'SV',
    isoCode3: 'SLV',
  },
  {
    text: 'Equatorial Guinea',
    countryCodes: ['240'],
    value: 'GQ',
    isoCode3: 'GNQ',
  },
  {
    text: 'Eritrea',
    countryCodes: ['291'],
    value: 'ER',
    isoCode3: 'ERI',
  },
  {
    text: 'Estonia',
    countryCodes: ['372'],
    value: 'EE',
    isoCode3: 'EST',
  },
  {
    text: 'Ethiopia',
    countryCodes: ['251'],
    value: 'ET',
    isoCode3: 'ETH',
  },
  {
    text: 'Falkland Islands',
    countryCodes: ['500'],
    value: 'FK',
    isoCode3: 'FLK',
  },
  {
    text: 'Faroe Islands',
    countryCodes: ['298'],
    value: 'FO',
    isoCode3: 'FRO',
  },
  {
    text: 'Fiji',
    countryCodes: ['679'],
    value: 'FJ',
    isoCode3: 'FJI',
  },
  {
    text: 'Finland',
    countryCodes: ['358'],
    value: 'FI',
    isoCode3: 'FIN',
  },
  {
    text: 'France',
    countryCodes: ['33'],
    value: 'FR',
    isoCode3: 'FRA',
  },
  {
    text: 'French Polynesia',
    countryCodes: ['689'],
    value: 'PF',
    isoCode3: 'PYF',
  },
  {
    text: 'Gabon',
    countryCodes: ['241'],
    value: 'GA',
    isoCode3: 'GAB',
  },
  {
    text: 'Gambia',
    countryCodes: ['220'],
    value: 'GM',
    isoCode3: 'GMB',
  },
  {
    text: 'Georgia',
    countryCodes: ['995'],
    value: 'GE',
    isoCode3: 'GEO',
  },
  {
    text: 'Germany',
    countryCodes: ['49'],
    value: 'DE',
    isoCode3: 'DEU',
  },
  {
    text: 'Ghana',
    countryCodes: ['233'],
    value: 'GH',
    isoCode3: 'GHA',
  },
  {
    text: 'Gibraltar',
    countryCodes: ['350'],
    value: 'GI',
    isoCode3: 'GIB',
  },
  {
    text: 'Greece',
    countryCodes: ['30'],
    value: 'GR',
    isoCode3: 'GRC',
  },
  {
    text: 'Greenland',
    countryCodes: ['299'],
    value: 'GL',
    isoCode3: 'GRL',
  },
  {
    text: 'Grenada',
    countryCodes: ['1-473'],
    value: 'GD',
    isoCode3: 'GRD',
  },
  {
    text: 'Guam',
    countryCodes: ['1-671'],
    value: 'GU',
    isoCode3: 'GUM',
  },
  {
    text: 'Guatemala',
    countryCodes: ['502'],
    value: 'GT',
    isoCode3: 'GTM',
  },
  {
    text: 'Guernsey',
    countryCodes: ['44-1481'],
    value: 'GG',
    isoCode3: 'GGY',
  },
  {
    text: 'Guinea',
    countryCodes: ['224'],
    value: 'GN',
    isoCode3: 'GIN',
  },
  {
    text: 'Guinea-Bissau',
    countryCodes: ['245'],
    value: 'GW',
    isoCode3: 'GNB',
  },
  {
    text: 'Guyana',
    countryCodes: ['592'],
    value: 'GY',
    isoCode3: 'GUY',
  },
  {
    text: 'Haiti',
    countryCodes: ['509'],
    value: 'HT',
    isoCode3: 'HTI',
  },
  {
    text: 'Honduras',
    countryCodes: ['504'],
    value: 'HN',
    isoCode3: 'HND',
  },
  {
    text: 'Hong Kong',
    countryCodes: ['852'],
    value: 'HK',
    isoCode3: 'HKG',
  },
  {
    text: 'Hungary',
    countryCodes: ['36'],
    value: 'HU',
    isoCode3: 'HUN',
  },
  {
    text: 'Iceland',
    countryCodes: ['354'],
    value: 'IS',
    isoCode3: 'ISL',
  },
  {
    text: 'India',
    countryCodes: ['91'],
    value: 'IN',
    isoCode3: 'IND',
  },
  {
    text: 'Indonesia',
    countryCodes: ['62'],
    value: 'ID',
    isoCode3: 'IDN',
  },
  {
    text: 'Iran',
    countryCodes: ['98'],
    value: 'IR',
    isoCode3: 'IRN',
  },
  {
    text: 'Iraq',
    countryCodes: ['964'],
    value: 'IQ',
    isoCode3: 'IRQ',
  },
  {
    text: 'Ireland',
    countryCodes: ['353'],
    value: 'IE',
    isoCode3: 'IRL',
  },
  {
    text: 'Isle of Man',
    countryCodes: ['44-1624'],
    value: 'IM',
    isoCode3: 'IMN',
  },
  {
    text: 'Israel',
    countryCodes: ['972'],
    value: 'IL',
    isoCode3: 'ISR',
  },
  {
    text: 'Italy',
    countryCodes: ['39'],
    value: 'IT',
    isoCode3: 'ITA',
  },
  {
    text: 'Ivory Coast',
    countryCodes: ['225'],
    value: 'CI',
    isoCode3: 'CIV',
  },
  {
    text: 'Jamaica',
    countryCodes: ['1-876'],
    value: 'JM',
    isoCode3: 'JAM',
  },
  {
    text: 'Japan',
    countryCodes: ['81'],
    value: 'JP',
    isoCode3: 'JPN',
  },
  {
    text: 'Jersey',
    countryCodes: ['44-1534'],
    value: 'JE',
    isoCode3: 'JEY',
  },
  {
    text: 'Jordan',
    countryCodes: ['962'],
    value: 'JO',
    isoCode3: 'JOR',
  },
  {
    text: 'Kazakhstan',
    countryCodes: ['7'],
    value: 'KZ',
    isoCode3: 'KAZ',
  },
  {
    text: 'Kenya',
    countryCodes: ['254'],
    value: 'KE',
    isoCode3: 'KEN',
  },
  {
    text: 'Kiribati',
    countryCodes: ['686'],
    value: 'KI',
    isoCode3: 'KIR',
  },
  {
    text: 'Kosovo',
    countryCodes: ['383'],
    value: 'XK',
    isoCode3: 'XKX',
  },
  {
    text: 'Kuwait',
    countryCodes: ['965'],
    value: 'KW',
    isoCode3: 'KWT',
  },
  {
    text: 'Kyrgyzstan',
    countryCodes: ['996'],
    value: 'KG',
    isoCode3: 'KGZ',
  },
  {
    text: 'Laos',
    countryCodes: ['856'],
    value: 'LA',
    isoCode3: 'LAO',
  },
  {
    text: 'Latvia',
    countryCodes: ['371'],
    value: 'LV',
    isoCode3: 'LVA',
  },
  {
    text: 'Lebanon',
    countryCodes: ['961'],
    value: 'LB',
    isoCode3: 'LBN',
  },
  {
    text: 'Lesotho',
    countryCodes: ['266'],
    value: 'LS',
    isoCode3: 'LSO',
  },
  {
    text: 'Liberia',
    countryCodes: ['231'],
    value: 'LR',
    isoCode3: 'LBR',
  },
  {
    text: 'Libya',
    countryCodes: ['218'],
    value: 'LY',
    isoCode3: 'LBY',
  },
  {
    text: 'Liechtenstein',
    countryCodes: ['423'],
    value: 'LI',
    isoCode3: 'LIE',
  },
  {
    text: 'Lithuania',
    countryCodes: ['370'],
    value: 'LT',
    isoCode3: 'LTU',
  },
  {
    text: 'Luxembourg',
    countryCodes: ['352'],
    value: 'LU',
    isoCode3: 'LUX',
  },
  {
    text: 'Macau',
    countryCodes: ['853'],
    value: 'MO',
    isoCode3: 'MAC',
  },
  {
    text: 'Macedonia',
    countryCodes: ['389'],
    value: 'MK',
    isoCode3: 'MKD',
  },
  {
    text: 'Madagascar',
    countryCodes: ['261'],
    value: 'MG',
    isoCode3: 'MDG',
  },
  {
    text: 'Malawi',
    countryCodes: ['265'],
    value: 'MW',
    isoCode3: 'MWI',
  },
  {
    text: 'Malaysia',
    countryCodes: ['60'],
    value: 'MY',
    isoCode3: 'MYS',
  },
  {
    text: 'Maldives',
    countryCodes: ['960'],
    value: 'MV',
    isoCode3: 'MDV',
  },
  {
    text: 'Mali',
    countryCodes: ['223'],
    value: 'ML',
    isoCode3: 'MLI',
  },
  {
    text: 'Malta',
    countryCodes: ['356'],
    value: 'MT',
    isoCode3: 'MLT',
  },
  {
    text: 'Marshall Islands',
    countryCodes: ['692'],
    value: 'MH',
    isoCode3: 'MHL',
  },
  {
    text: 'Mauritania',
    countryCodes: ['222'],
    value: 'MR',
    isoCode3: 'MRT',
  },
  {
    text: 'Mauritius',
    countryCodes: ['230'],
    value: 'MU',
    isoCode3: 'MUS',
  },
  {
    text: 'Mayotte',
    countryCodes: ['262'],
    value: 'YT',
    isoCode3: 'MYT',
  },
  {
    text: 'Mexico',
    countryCodes: ['52'],
    value: 'MX',
    isoCode3: 'MEX',
  },
  {
    text: 'Micronesia',
    countryCodes: ['691'],
    value: 'FM',
    isoCode3: 'FSM',
  },
  {
    text: 'Moldova',
    countryCodes: ['373'],
    value: 'MD',
    isoCode3: 'MDA',
  },
  {
    text: 'Monaco',
    countryCodes: ['377'],
    value: 'MC',
    isoCode3: 'MCO',
  },
  {
    text: 'Mongolia',
    countryCodes: ['976'],
    value: 'MN',
    isoCode3: 'MNG',
  },
  {
    text: 'Montenegro',
    countryCodes: ['382'],
    value: 'ME',
    isoCode3: 'MNE',
  },
  {
    text: 'Montserrat',
    countryCodes: ['1-664'],
    value: 'MS',
    isoCode3: 'MSR',
  },
  {
    text: 'Morocco',
    countryCodes: ['212'],
    value: 'MA',
    isoCode3: 'MAR',
  },
  {
    text: 'Mozambique',
    countryCodes: ['258'],
    value: 'MZ',
    isoCode3: 'MOZ',
  },
  {
    text: 'Myanmar',
    countryCodes: ['95'],
    value: 'MM',
    isoCode3: 'MMR',
  },
  {
    text: 'Namibia',
    countryCodes: ['264'],
    value: 'NA',
    isoCode3: 'NAM',
  },
  {
    text: 'Nauru',
    countryCodes: ['674'],
    value: 'NR',
    isoCode3: 'NRU',
  },
  {
    text: 'Nepal',
    countryCodes: ['977'],
    value: 'NP',
    isoCode3: 'NPL',
  },
  {
    text: 'Netherlands',
    countryCodes: ['31'],
    value: 'NL',
    isoCode3: 'NLD',
  },
  {
    text: 'Netherlands Antilles',
    countryCodes: ['599'],
    value: 'AN',
    isoCode3: 'ANT',
  },
  {
    text: 'New Caledonia',
    countryCodes: ['687'],
    value: 'NC',
    isoCode3: 'NCL',
  },
  {
    text: 'New Zealand',
    countryCodes: ['64'],
    value: 'NZ',
    isoCode3: 'NZL',
  },
  {
    text: 'Nicaragua',
    countryCodes: ['505'],
    value: 'NI',
    isoCode3: 'NIC',
  },
  {
    text: 'Niger',
    countryCodes: ['227'],
    value: 'NE',
    isoCode3: 'NER',
  },
  {
    text: 'Nigeria',
    countryCodes: ['234'],
    value: 'NG',
    isoCode3: 'NGA',
  },
  {
    text: 'Niue',
    countryCodes: ['683'],
    value: 'NU',
    isoCode3: 'NIU',
  },
  {
    text: 'North Korea',
    countryCodes: ['850'],
    value: 'KP',
    isoCode3: 'PRK',
  },
  {
    text: 'Northern Mariana Islands',
    countryCodes: ['1-670'],
    value: 'MP',
    isoCode3: 'MNP',
  },
  {
    text: 'Norway',
    countryCodes: ['47'],
    value: 'NO',
    isoCode3: 'NOR',
  },
  {
    text: 'Oman',
    countryCodes: ['968'],
    value: 'OM',
    isoCode3: 'OMN',
  },
  {
    text: 'Pakistan',
    countryCodes: ['92'],
    value: 'PK',
    isoCode3: 'PAK',
  },
  {
    text: 'Palau',
    countryCodes: ['680'],
    value: 'PW',
    isoCode3: 'PLW',
  },
  {
    text: 'Palestine',
    countryCodes: ['970'],
    value: 'PS',
    isoCode3: 'PSE',
  },
  {
    text: 'Panama',
    countryCodes: ['507'],
    value: 'PA',
    isoCode3: 'PAN',
  },
  {
    text: 'Papua New Guinea',
    countryCodes: ['675'],
    value: 'PG',
    isoCode3: 'PNG',
  },
  {
    text: 'Paraguay',
    countryCodes: ['595'],
    value: 'PY',
    isoCode3: 'PRY',
  },
  {
    text: 'Peru',
    countryCodes: ['51'],
    value: 'PE',
    isoCode3: 'PER',
  },
  {
    text: 'Philippines',
    countryCodes: ['63'],
    value: 'PH',
    isoCode3: 'PHL',
  },
  {
    text: 'Pitcairn',
    countryCodes: ['64'],
    value: 'PN',
    isoCode3: 'PCN',
  },
  {
    text: 'Poland',
    countryCodes: ['48'],
    value: 'PL',
    isoCode3: 'POL',
  },
  {
    text: 'Portugal',
    countryCodes: ['351'],
    value: 'PT',
    isoCode3: 'PRT',
  },
  {
    text: 'Puerto Rico',
    countryCodes: ['1-787', '1-939'],
    value: 'PR',
    isoCode3: 'PRI',
  },
  {
    text: 'Qatar',
    countryCodes: ['974'],
    value: 'QA',
    isoCode3: 'QAT',
  },
  {
    text: 'Republic of the Congo',
    countryCodes: ['242'],
    value: 'CG',
    isoCode3: 'COG',
  },
  {
    text: 'Reunion',
    countryCodes: ['262'],
    value: 'RE',
    isoCode3: 'REU',
  },
  {
    text: 'Romania',
    countryCodes: ['40'],
    value: 'RO',
    isoCode3: 'ROU',
  },
  {
    text: 'Russia',
    countryCodes: ['7'],
    value: 'RU',
    isoCode3: 'RUS',
  },
  {
    text: 'Rwanda',
    countryCodes: ['250'],
    value: 'RW',
    isoCode3: 'RWA',
  },
  {
    text: 'Saint Barthelemy',
    countryCodes: ['590'],
    value: 'BL',
    isoCode3: 'BLM',
  },
  {
    text: 'Saint Helena',
    countryCodes: ['290'],
    value: 'SH',
    isoCode3: 'SHN',
  },
  {
    text: 'Saint Kitts and Nevis',
    countryCodes: ['1-869'],
    value: 'KN',
    isoCode3: 'KNA',
  },
  {
    text: 'Saint Lucia',
    countryCodes: ['1-758'],
    value: 'LC',
    isoCode3: 'LCA',
  },
  {
    text: 'Saint Martin',
    countryCodes: ['590'],
    value: 'MF',
    isoCode3: 'MAF',
  },
  {
    text: 'Saint Pierre and Miquelon',
    countryCodes: ['508'],
    value: 'PM',
    isoCode3: 'SPM',
  },
  {
    text: 'Saint Vincent and the Grenadines',
    countryCodes: ['1-784'],
    value: 'VC',
    isoCode3: 'VCT',
  },
  {
    text: 'Samoa',
    countryCodes: ['685'],
    value: 'WS',
    isoCode3: 'WSM',
  },
  {
    text: 'San Marino',
    countryCodes: ['378'],
    value: 'SM',
    isoCode3: 'SMR',
  },
  {
    text: 'Sao Tome and Principe',
    countryCodes: ['239'],
    value: 'ST',
    isoCode3: 'STP',
  },
  {
    text: 'Saudi Arabia',
    countryCodes: ['966'],
    value: 'SA',
    isoCode3: 'SAU',
  },
  {
    text: 'Senegal',
    countryCodes: ['221'],
    value: 'SN',
    isoCode3: 'SEN',
  },
  {
    text: 'Serbia',
    countryCodes: ['381'],
    value: 'RS',
    isoCode3: 'SRB',
  },
  {
    text: 'Seychelles',
    countryCodes: ['248'],
    value: 'SC',
    isoCode3: 'SYC',
  },
  {
    text: 'Sierra Leone',
    countryCodes: ['232'],
    value: 'SL',
    isoCode3: 'SLE',
  },
  {
    text: 'Singapore',
    countryCodes: ['65'],
    value: 'SG',
    isoCode3: 'SGP',
  },
  {
    text: 'Sint Maarten',
    countryCodes: ['1-721'],
    value: 'SX',
    isoCode3: 'SXM',
  },
  {
    text: 'Slovakia',
    countryCodes: ['421'],
    value: 'SK',
    isoCode3: 'SVK',
  },
  {
    text: 'Slovenia',
    countryCodes: ['386'],
    value: 'SI',
    isoCode3: 'SVN',
  },
  {
    text: 'Solomon Islands',
    countryCodes: ['677'],
    value: 'SB',
    isoCode3: 'SLB',
  },
  {
    text: 'Somalia',
    countryCodes: ['252'],
    value: 'SO',
    isoCode3: 'SOM',
  },
  {
    text: 'South Africa',
    countryCodes: ['27'],
    value: 'ZA',
    isoCode3: 'ZAF',
  },
  {
    text: 'South Korea',
    countryCodes: ['82'],
    value: 'KR',
    isoCode3: 'KOR',
  },
  {
    text: 'South Sudan',
    countryCodes: ['211'],
    value: 'SS',
    isoCode3: 'SSD',
  },
  {
    text: 'Spain',
    countryCodes: ['34'],
    value: 'ES',
    isoCode3: 'ESP',
  },
  {
    text: 'Sri Lanka',
    countryCodes: ['94'],
    value: 'LK',
    isoCode3: 'LKA',
  },
  {
    text: 'Sudan',
    countryCodes: ['249'],
    value: 'SD',
    isoCode3: 'SDN',
  },
  {
    text: 'Suriname',
    countryCodes: ['597'],
    value: 'SR',
    isoCode3: 'SUR',
  },
  {
    text: 'Svalbard and Jan Mayen',
    countryCodes: ['47'],
    value: 'SJ',
    isoCode3: 'SJM',
  },
  {
    text: 'Swaziland',
    countryCodes: ['268'],
    value: 'SZ',
    isoCode3: 'SWZ',
  },
  {
    text: 'Sweden',
    countryCodes: ['46'],
    value: 'SE',
    isoCode3: 'SWE',
  },
  {
    text: 'Switzerland',
    countryCodes: ['41'],
    value: 'CH',
    isoCode3: 'CHE',
  },
  {
    text: 'Syria',
    countryCodes: ['963'],
    value: 'SY',
    isoCode3: 'SYR',
  },
  {
    text: 'Taiwan',
    countryCodes: ['886'],
    value: 'TW',
    isoCode3: 'TWN',
  },
  {
    text: 'Tajikistan',
    countryCodes: ['992'],
    value: 'TJ',
    isoCode3: 'TJK',
  },
  {
    text: 'Tanzania',
    countryCodes: ['255'],
    value: 'TZ',
    isoCode3: 'TZA',
  },
  {
    text: 'Thailand',
    countryCodes: ['66'],
    value: 'TH',
    isoCode3: 'THA',
  },
  {
    text: 'Togo',
    countryCodes: ['228'],
    value: 'TG',
    isoCode3: 'TGO',
  },
  {
    text: 'Tokelau',
    countryCodes: ['690'],
    value: 'TK',
    isoCode3: 'TKL',
  },
  {
    text: 'Tonga',
    countryCodes: ['676'],
    value: 'TO',
    isoCode3: 'TON',
  },
  {
    text: 'Trinidad and Tobago',
    countryCodes: ['1-868'],
    value: 'TT',
    isoCode3: 'TTO',
  },
  {
    text: 'Tunisia',
    countryCodes: ['216'],
    value: 'TN',
    isoCode3: 'TUN',
  },
  {
    text: 'Turkey',
    countryCodes: ['90'],
    value: 'TR',
    isoCode3: 'TUR',
  },
  {
    text: 'Turkmenistan',
    countryCodes: ['993'],
    value: 'TM',
    isoCode3: 'TKM',
  },
  {
    text: 'Turks and Caicos Islands',
    countryCodes: ['1-649'],
    value: 'TC',
    isoCode3: 'TCA',
  },
  {
    text: 'Tuvalu',
    countryCodes: ['688'],
    value: 'TV',
    isoCode3: 'TUV',
  },
  {
    text: 'U.S. Virgin Islands',
    countryCodes: ['1-340'],
    value: 'VI',
    isoCode3: 'VIR',
  },
  {
    text: 'Uganda',
    countryCodes: ['256'],
    value: 'UG',
    isoCode3: 'UGA',
  },
  {
    text: 'Ukraine',
    countryCodes: ['380'],
    value: 'UA',
    isoCode3: 'UKR',
  },
  {
    text: 'United Arab Emirates',
    countryCodes: ['971'],
    value: 'AE',
    isoCode3: 'ARE',
  },
  {
    text: 'United Kingdom',
    countryCodes: ['44'],
    value: 'GB',
    isoCode3: 'GBR',
  },
  {
    text: 'United States',
    countryCodes: ['1'],
    value: 'US',
    isoCode3: 'USA',
  },
  {
    text: 'Uruguay',
    countryCodes: ['598'],
    value: 'UY',
    isoCode3: 'URY',
  },
  {
    text: 'Uzbekistan',
    countryCodes: ['998'],
    value: 'UZ',
    isoCode3: 'UZB',
  },
  {
    text: 'Vanuatu',
    countryCodes: ['678'],
    value: 'VU',
    isoCode3: 'VUT',
  },
  {
    text: 'Vatican',
    countryCodes: ['379'],
    value: 'VA',
    isoCode3: 'VAT',
  },
  {
    text: 'Venezuela',
    countryCodes: ['58'],
    value: 'VE',
    isoCode3: 'VEN',
  },
  {
    text: 'Vietnam',
    countryCodes: ['84'],
    value: 'VN',
    isoCode3: 'VNM',
  },
  {
    text: 'Wallis and Futuna',
    countryCodes: ['681'],
    value: 'WF',
    isoCode3: 'WLF',
  },
  {
    text: 'Western Sahara',
    countryCodes: ['212'],
    value: 'EH',
    isoCode3: 'ESH',
  },
  {
    text: 'Yemen',
    countryCodes: ['967'],
    value: 'YE',
    isoCode3: 'YEM',
  },
  {
    text: 'Zambia',
    countryCodes: ['260'],
    value: 'ZM',
    isoCode3: 'ZMB',
  },
  {
    text: 'Zimbabwe',
    countryCodes: ['263'],
    value: 'ZW',
    isoCode3: 'ZWE',
  },
]

export const YesNoOption = [
  { text: 'Yes', value: '1' },
  { text: 'No', value: '0' },
]

export function getFormattedDate(date) {
  const date1 = new Date(date);                 // {object Date}
  const monthNames = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  let year = date1.getFullYear();
  let month = monthNames[date1.getMonth()];
  let day = date1.getDate().toString().padStart(2, '0');
  return year + '-' + month + '-' + day;
}

export const statusFailureCase = [3, 6, 8, 9, 11]
export const statusFailureCase1 = [5, 6, 7, 8, 9, 10, 11]

export const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

export const monthNamesShort = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];

export const mobileNumberRegex = /^[\+]?[(]?[0-9]{2}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{5,}$/im

