import { ASSET_BASE_URL } from '../../../environment';

const dashboardIcon = ASSET_BASE_URL + 'images/img/dashboard.svg';
const userIcon = "./user-man.svg";
const logoWhite = ASSET_BASE_URL + 'images/img/white-logo.png'
const logoBlack = ASSET_BASE_URL + 'images/img/black-logo.png'
const footerLogo = ASSET_BASE_URL + 'images/img/footer-logo.png'

const youtube = ASSET_BASE_URL + 'images/img/social/youtube.svg'
const facebook = ASSET_BASE_URL + 'images/img/social/facebook.svg'
const instgram = ASSET_BASE_URL + 'images/img/social/instgram.svg'
const twitter = ASSET_BASE_URL + 'images/img/social/twitter.svg'


export {
  dashboardIcon,
  userIcon,
  logoWhite,
  logoBlack,
  footerLogo,
  youtube, facebook, instgram, twitter
}
